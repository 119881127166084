import React from "react"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import HeroPagesCenter from "../../../components/heroes/hero-pages-center"
import ServiceData from "../../../content/services/customer-success/index.json"
import DynamicForm from "../../../components/forms/dynamic-form"
import { Container, Row, Col } from 'react-bootstrap'
import { injectIntl } from "gatsby-plugin-react-intl"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ImageFluid from "../../../components/misc/image-fluid";

const ServiceCustomerSuccessPage = ({ intl, location }) => (
    <Layout>
        <Seo lang={intl.locale} title={ServiceData[intl.locale].title} description={ServiceData[intl.locale].text} pathname={location.pathname} />
        <HeroPagesCenter theme={`primary`} content={ServiceData[intl.locale]} />
        <div className={`service`}>
            <Container>
                <Row noGutters={true}>
                    <Col md={4}>
                        <div className={`top-small-overflow-wrapper`}>
                            <div className={`service-content`}>
                                <div className={`title`}>{ServiceData[intl.locale].content.title}</div>
                                <div className={`reasons`}>
                                    {ServiceData[intl.locale].content.reasons.map((reason, i) => {
                                        return (
                                            <div key={i} className={`reason`}>
                                                <FontAwesomeIcon icon="arrow-right" />
                                                {reason.text}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className={`image`}><ImageFluid alt={``} filename={ServiceData[intl.locale].content.image} /></div>
                            </div>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className={`top-overflow-wrapper`}>
                            <div className={`service-form`}>
                                <DynamicForm type={`serviceInformationRequest`} defaultValues={{service: `Customer Success`}} redirect={`/forms/thank-you/`} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </Layout>
)

export default injectIntl(ServiceCustomerSuccessPage)
